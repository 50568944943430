.card {
  border-radius: 15px 15px 15px 15px !important;
}

.buttonGrid {
  display: flex;
  flex-direction: row;
}

.htmlButton {
  height: 2.5rem;
  width: 10rem;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(90deg, #337fc9 0%, #2057a6 86.04%);
  color: white;
  border: none;
  border-radius: 30px;
  margin: 2px;
  cursor: pointer;
  font-family: "Lato";
}

.htmlButtonDisabled {
  /* opacity: .5 !important; */
  pointer-events: none;
  cursor: not-allowed !important;
  height: 2.5rem;
  width: 10rem;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(93.3deg, #517cba 22.4%, #517cba 100%);
  color: white;
  border: none;
  border-radius: 30px;
  margin: 2px;
  font-family: "Lato";

}

.DownloadAsExcelButton {
  display: flex;
  justify-content: center;
  /* border: solid 2px chartreuse; */
}

.DownloadAsPdfButton {
  display: flex;
  justify-content: center;
}

.radioButtonCss {
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: Lato !important;
}

.loaderCss {
  position: absolute;
  top: 50%;
  left: 50%;
}
