.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  height: 1.5rem !important;
  border-bottom: 1px solid #333333 !important;
  font-family: "Lato" !important;
  font-size: 13px !important;
}
label {
  font-family: "Lato" !important;
}


@media only screen and (max-width: 820px) {
  .appDiv {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .mobileScreen {
    display: none;
  }
}

.mobileScreen {
  height: 50vh;
  width: 50vw;
  position: absolute;
  top: 25%;
  left: 25%;
  /* margin: -50px 0 0 -50px; */
  font-size: 26px;
  font-weight: bolder;
}


.MuiCardContent-root:last-child {
  padding-bottom: 5px !important;
}

.MuiCardContent-root {
  padding: 5px !important;
}
/* .MuiGrid-root{
  padding:1rem !important
} */

.MuiTableCell-root {
  /* font-size: 12px ; */
  padding: 3px 2px 2px 0px !important;
  font-family: "Lato" !important;
  text-align: center !important;
  /* border-bottom: none; */
}

.MuiTableRow-root:last-child{
  border: none;
}

.MuiInputBase-input-MuiInput-input{
  font: revert !important;
  font-family: 'Lato' !important;
}

.MuiButtonBase-root-MuiIconButton-root {
  color: #fedb77 !important;
}

.MuiButtonBase-root-MuiButton-root {
  color: #282c34 !important;
}
.MuiTypography-root {
  font-family: "Lato" !important;
  /* font-size: 12px !important; */
}
.MuiInputBase-root-MuiInput-root {
  font-family: "Lato" !important;
  font-size: 12px !important;
  margin-top: 18px;
}

.MuiDataGrid-virtualScrollerContent {
  height: fit-content !important;
}
::-webkit-scrollbar {
  width: 15px;
  height: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
}
