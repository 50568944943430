.row1Left {
  height: 1.84rem; 
   background-color: white;
}

.row1Right {
  height: 1.84rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.row2Left {
  height: 4rem;
  /* border: solid 2px red; */
  /* border-radius: 0 0 20px 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.row2Right {
  height: 4rem;
  /* padding-top: 20px; */
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  padding-right: 2rem;
  align-items: center;
  background-color: white;
  
}

.row1Right,
.row2Left {
  /* background-color: #333333; */
  /* color: white; */
}

.belowCut {
  right: -30px;
  height: 100%;
}

.topCut {
  /* margin-left: 0.1rem; */
  height: 100%;
  /* border: solid 2px lightsalmon; */
}

.row1Cut {
}

.phoneNumber {
  padding-right: 2rem;
}

.logoSvg {
  /* border: solid 2px red; */
  height: 3.8rem !important;
}

.tickerRow{
  background-color: white;

}
