.mainDiv {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.emptyDiv {
  height: 350px;
}

.tableCss {
  width: 100%;
}

.tableCellCss {
  font-weight: bold !important;
}

.noInvestmentFirstLineCss {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.noInvestmentSecondLineCss {
  display: flex;
  justify-content: center;
  align-items: center;
}
