.bubbleBox:hover {
  background-color: #fef7e5;
}

.mainBody {
  padding-right: 3%;
  padding-left: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.card {
  padding: 20px;
}

.cardBottomCommon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.cardBottomLeft {
  padding-right: 20px;
}

.cardBottomRight {
  padding-left: 20px;
}

.graphs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat23Grid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px !important;
}

.cat23Card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 15px 15px !important;
  width: 920px;
  height: 435px;
}

.loaderCss {
  position: absolute;
  top: 50%;
  left: 50%;
}
