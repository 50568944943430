.otploader__div {
 height: 100vh;
  z-index: 1000;
  position: fixed;
    width: 100vw;
    margin: 0;
}

.otpwrapper {
    height: 182.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000;
    position: absolute;
    width: 165vw;
}
.otpwrapper-new {
    height: 182.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1000;
    position: absolute;
    width: 143vw;
}


.otploading-spinner {
 /* margin-bottom: 2rem; */
 position: relative;
    left: -15%;
    top:-25%; 
}















