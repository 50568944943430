.modalloader__div {
    height: 100vh;
     z-index: 1000;
     position: fixed;
       width: 100vw;
       margin: 0;
   }
   
   .modalwrapper {
     height: 100vh;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
       z-index: 1000;
       position: absolute;
       width: 100vw;
     
   
     
   }
   
   
   .modalloading-spinner {
    /* margin-bottom: 2rem; */
    position: relative;
       /* left: -15%; */
       /* top:-25%;  */
   }
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   
   