.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
}

.inputFormat {
  width: 30vw;
  border-radius: 10px;
  outline: none;
  min-width: 300px;
  color: #2d9cdb;
  border: 1px solid rgba(79, 128, 226, 1);
  padding: 5px;
}

.buttonFormat {
  width: 150px;
  padding: 5px;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  background-color: rgb(36, 40, 43);
  font-family: "Poppins";
  border: none;
}
/* 
      @media (max-width: 950px) {
        .imageContainer {
          visibility: collapse;
          flex:0;
          background-color:red !important;
        }
      
      } */
/* .background {
  background-image: url("https://integrowaif.kfintech.com/images/login-bg.svg");
} */
.background {
  background-image: url("../svg/MainBackground.svg");
  /* background-color: #282c34 ; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.radioButtonCss {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato !important;
  max-height: 7vh !important ;
}

.switchButton {
  /* border-right: 2px solid #337fc9; */
  height: 5vh;
  width: 90px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: capitalize;
}

.switchBlueButton {
  /* border-right: 2px solid #337fc9; */
  height: 5vh;
  width: 90px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-transform: capitalize;
  background-color: #337fc9;
}
