.outerContainer {
  overflow: auto;
  /* display: flex; */
  flex-direction: row;
  font-family: "Lato";
}

.lineGrid__row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lineGrid__row2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem !important;
  /* border: solid 2px coral; */
}

.lineGrid__row3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: solid 2px slateblue; */
}

.card {
  border-radius: 15px 15px 15px 15px !important;
  margin: 1rem 1rem 1rem 1rem;
}

.lineGrid__grey {
  color: #6d6e71;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 700;
}

.lineGrid__last {
  text-align: end;
  color: #6d6e71;
  font-size: 12px;
}

.lineGrid__lastValue {
  text-align: end;
  font-weight: bold;
}

.pieGrid__col1 {
  /* border: solid 1px red; */
}

.backButton {
  padding: 0 0 0 0 !important;
}

.inner4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.absoluteReturnGreenCss {
  color: green;
  font-weight: bold;
  text-align: justify;
  display: flex;
  justify-content: center;
}

.absoluteReturnRedCss {
  color: #e23118;
  font-weight: bold;
  text-align: justify;
  display: flex;
  justify-content: center;
}
.absoluteReturnBlackCss {
  color: #000000;
  font-weight: bold;
  text-align: justify;
  display: flex;
  justify-content: center;
}
