@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
.otpbox {


  width: 30rem;
    height: auto;
    min-width: 28rem;
    /* min-height: 27rem; */
    text-align: center;
    background-color: rgba(255, 255, 255, 0.750);
    padding: 1rem 0.5rem 0rem 0.5rem;
    box-shadow: 2px 2px 56px rgba(209, 184, 185, 0.78);
    border-radius: 10px;
}

/* .arrow{
    position: relative;
    top: 51px;
    left: -208px;
    scale:150%;
    color: #404040;

} */
.ot{
    display: flex;
   text-align: center;
    font-family: 'Poppins';
    font-size: 1.2rem;
    line-height: 1rem;
    font-weight: bold;
    justify-content: center;

    /* padding-bottom: 1rem; */
  
}
.user-mail{
  margin-top: 2rem;
  font-family: 'Poppins';
  font-weight: bolder;
  margin-bottom: 0.6rem;
}
.email{
  font-family: 'Poppins';
  font-weight: bolder;
}

.otp-stack{
  display: flex;
  justify-content: center;
  /* margin-top: 1rem; */
  scale:100%;
}

.otp-input{
    width: 50px;
    height: 40px !important;
    border: 2px red;
    font-weight: bolder;
    font-size: 1.4rem !important;
    color: rgb(20, 19, 18);
    text-align: center;
    border-radius: 6px;
    outline: 1px solid rgba(118, 117, 117, 0.205);
    background-color: #cfcece85;
    scale: 80%;
}
.resend{
  color: rgb(22, 22, 22);
  font-family: 'Poppins';
  font-weight: bold;
  font-size: smaller;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 10px;
  

}
.verify-btn{
  display: flex;
  background-color: #2057A6;
  border-radius: 10px;
  color:wheat;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 3rem;
  margin-left: 35%;
  font-size: 1.2rem;
  font-family: Poppins;
  margin-top: 1rem;
}
