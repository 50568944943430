.mainGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchButtonGrid {
  display: flex;
  justify-content: center;
  align-items: center;
}
