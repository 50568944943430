.profileFont {
  font-family: Lato;
  font-weight: bold;
  font-size: 25px;
  padding-left: 5.9%;
  padding-top: 2%;

  color: #333333;
}

.profileBox {
  margin-top: 3%;
  margin-bottom: 3%;
  font-family: Lato;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profileTextField {
  font-family: Lato;
  justify-content: left;
  align-items: center;
  display: flex;
  padding: 5px;
}

.loaderCss {
  position: absolute;
  top: 50%;
  left: 50%;
}
.mainDiv {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.emptyDiv {
  height: 350px;
}

.tableCss {
  width: 100%;
}

.tableCellCss {
  font-weight: bold !important;
  font-size: 13px !important;
}

.noInvestmentFirstLineCss {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.noInvestmentSecondLineCss {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18vh;
}
.profileTable {
  font-family: Lato;
  font-size: 12px !important;
}
