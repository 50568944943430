.logoSvg {
  height: 3.8rem !important;
}

.card {
  background-color: white;
  width: 900px;
  /* border-radius: 40px !important; */
  width: 1000px;
  /* border: solid 2px #fed561; */
  box-shadow: none !important;
}

.inputFormat {
  width: 18vw;
  outline: none;
  min-width: 300px;
  color: #2d9cdb;
  border: 1px solid rgba(79, 128, 226, 1);
  padding: 5px;
}

.otpCard {
  background-color: white;
  width: 800px;
  border-radius: 40px !important;
  border: solid 5px #fed561;
}

.signUpTextField {
  font-size: 16px !important;
  border: "solid 2px red" !important;
}
