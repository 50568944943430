.swalPopup{
    width: 25%;
    /* height: 77%; */
}
.swalHtmlContainer{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    align-items: center !important;
    /* height: rem !important; */
}
.title1{
    font-size: 1.5rem;
}
.swalWarningIcon{
    width: 3.5rem;
    height: 3.5rem;
} 
.swal2-icon-content.swalWarningContent {
    font-size: 2.75rem !important;
    height: 1rem;
    width: 1rem;
}