/* .leftTile:hover {
  background: linear-gradient(90.71deg, #ad9042 0%, #fed561 100%);
  cursor: pointer;
} */

.leftTile {
  /* height: 40; */
  color: var(--leftnavbtncolor);
  display: flex;
  padding-bottom: 10px;
  /* Padding bottom provided by mui grid container spacing 2 */
  font-family: Lato;
  font-size: 14px;
  width: 100%;
  /* align-items: center; */
  justify-items: center;
  /* border-bottom: #fed561 solid 1px; */
}
.leftTileGolden {
  /* height: 40; */
  color: white;
  display: flex;
  padding-bottom: 10px;
  /* Padding bottom provided by mui grid container spacing 2 */
  font-family: Lato;
  font-size: 14px;
  width: 100%;
  /* allign-items: center; */
  justify-items: center;
  /* background: linear-gradient(90.71deg, #ad9042 0%, #fed561 100%); */
  /* cursor: pointer; */
  background: var(--leftnavbtncolorbg);
}

.tileSvg {
  margin-right: 10px;
  margin-left: 15px;
  font-size: 20;
}

.tile2Svg {
  margin-right: 4px;
  margin-left: 15px;
  font-size: 20;
  padding-right: 5px;
  padding-top: 2px;
  /* border: solid 1px red; */
}

.leftNavBar {
  /* margin-top: 80px !important; */
  background-color: var(--leftnavcolor);
  font-family: Lato;
  min-height: 100vh;
  box-shadow: 0px 2px 18px 8px #00000026;
  z-index: 4 !important ;
}

.lastTile {
  /* border: "2px solid yellow";*/
  min-height: 100vh;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #2057a6;
  border-radius: 50%;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8.6rem;
}

.tileSvg2 {
  margin-right: 10px;
  margin-left: 15px;
  font-size: 20;
}

.tile2Svg2 {
  margin-right: 4px;
  margin-left: 11px;
  font-size: 20;
  padding-right: 5px;
  padding-top: 2px;
  /* border: solid 1px red; */
}