.card {
  background-color: white;
   border-radius: 40px !important;
  width: 800px;
  border: solid 2px #282c34;
  /* box-shadow: none !important; */
}

.inputFormat {
  width: 18vw;
  outline: none;
  min-width: 300px;
  color: #2d9cdb;
  border: 1px solid rgba(79, 128, 226, 1);
  padding: 5px;
}

.otpCard {
  background-color: white;
  width: 800px;
  border-radius: 40px !important;
  border: solid 5px #fed561;
}

.signUpTextField {
  font-size: 16px !important;
  border: "solid 2px red" !important;
}


.container {
    background-image: url("../svg/MainBackground.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
  }